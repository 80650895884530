import { useState } from "react";
import ReactModal from "react-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";

import useUIStore from "../stores/uiStore";
import useAppStore from "../stores/appStore";

export default function DeviceNameModal() {
  const checkDeviceName = useAppStore((state) => state.checkDeviceName);
  const showDeviceNameModal = useUIStore((state) => state.showDeviceNameModal);
  const requireDeviceNamePasscode = useUIStore((state) => state.requireDeviceNamePasscode);
  const showProfileModal = useUIStore((state) => state.showProfileModal);

  const [showError, setShowError] = useState(false);

  return (
    <>
      <ReactModal
        ariaHideApp={false}
        isOpen={showDeviceNameModal}
        contentLabel="Set Device Name"
        style={{
          overlay: {
            zIndex: 99999999,
          },
          content: {
            color: "lightsteelblue",
            backgroundColor: "#2d2e31",
          },
        }}>
        <div className="modal-body">
          <div className="modal-header">
            <h4 className="text-center">Set Device Name</h4>
            {/* only show the modal close button if the profile modal is also open, so it won't show on initial login/app load */}
            {showProfileModal && (
              <button onClick={() => useUIStore.setState({ showDeviceNameModal: false, requireDeviceNamePasscode: false })}>
                Close <i className="fa-solid fa-times"></i>
              </button>
            )}
          </div>
          <div className="modal-content">
            <Formik
              enableReinitialize={true}
              initialValues={{ device_name: "", passcode: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.device_name || values.device_name === "") {
                  errors.device_name = "Required";
                }
                if (requireDeviceNamePasscode && (!values.passcode || values.passcode === "" || values.passcode !== "9999")) {
                  errors.passcode = "Incorrect passcode";
                } else {
                  useUIStore.setState({ requireDeviceNamePasscode: false });
                }
                return errors;
              }}
              onSubmit={async (values) => {
                const matchFound = await checkDeviceName(values);
                if (!matchFound) {
                  setShowError(true);
                }
              }}>
              {({ isSubmitting }) => (
                <Form>
                  <div className="container">
                    {!requireDeviceNamePasscode && (
                      <div className="row">
                        <div className="col">
                          <label className="formik-label" htmlFor="note">
                            Device Name
                          </label>
                          <Field name="device_name" label="device_name" type="text" />
                          <ErrorMessage name="device_name" component="div" className="formik-error" />

                          {showError && (
                            <div>
                              <p className="red">Could not find any matching equipment with this name.</p>
                            </div>
                          )}

                          <h3>To find your device name, go to Settings > General > About > Name.</h3>
                          <h3>There should be a 3 or 4 digit number. That is your iPads device name.</h3>
                        </div>
                      </div>
                    )}
                    {requireDeviceNamePasscode && (
                      <div className="row">
                        <div className="col">
                          <label className="formik-label" htmlFor="note">
                            Passcode
                          </label>
                          <Field name="passcode" label="passcode" type="text" />
                          <ErrorMessage name="passcode" component="div" className="formik-error" />

                          {showError && (
                            <div>
                              <p className="red">Incorrect passcode.</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="action-stuck-bottom">
                    <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                      Set Device Name
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
