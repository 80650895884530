import useUIStore from "../../stores/uiStore";
import useAppStore from "../../stores/appStore";

export default function SelectTicketMenu() {
  const showTicketMenu = useUIStore((state) => state.showTicketMenu);
  const useChopperAndBaggerTickets = useAppStore((state) => state.use_chopper_and_bagger_tickets);

  const openModal = (callback) => {
    window.farmFieldInit = false;
    callback();
  };

  return (
    <>
      {showTicketMenu && (
        <div className="right-drawer ticket-menu">
          <div className="action-list">
            <div className="action" onClick={() => useUIStore.setState({ showGasTicketModal: true, showTicketMenu: false })}>
              <div className="name">Fuel Ticket</div>
            </div>
            {/* Removed on 4/21/23 */}
            {/* <div
              className="action"
              onClick={() => {
                openModal(() => useUIStore.setState({ showDumpTicketModal: true, showTicketMenu: false }));
              }}>
              <div className="name">Dump Ticket</div>
            </div> */}
            {useChopperAndBaggerTickets && (
              <div
                className="action"
                onClick={() => {
                  openModal(() => useUIStore.setState({ showSilageBaggerModal: true, showTicketMenu: false }));
                }}>
                <div className="name">Silage Bagger</div>
              </div>
            )}
            <div
              className="action"
              onClick={() => {
                openModal(() => useUIStore.setState({ showSilageTruckModal: true, showTicketMenu: false }));
              }}>
              <div className="name">Silage Truck</div>
            </div>
            {useChopperAndBaggerTickets && (
              <div className="action" onClick={() => useUIStore.setState({ showSilageChopperModal: true, showTicketMenu: false })}>
                <div className="name">Silage Chopper</div>
              </div>
            )}
            <div
              className="action"
              onClick={() => {
                openModal(() => useUIStore.setState({ showHoursTicketModal: true, showTicketMenu: false }));
              }}>
              <div className="name">Hours Ticket</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
