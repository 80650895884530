import create from "zustand";
import { devtools } from "zustand/middleware";

import client, { noIntercept } from "../lib/client";
import { initialDataUrl, checkDeviceMatch, logErrorUrl } from "../lib/endpoints";

import useUIStore from "./uiStore";
import useCrewStore from "./crewStore";
import useDumpLocationStore from "./dumpLocationStore";
import useFieldStore from "./fieldStore";
import { processFieldFeatures } from "../lib/field";
import useEquipmentStore from "./equipmentStore";
import useCustomerMapPinStore from "./customerMapPinStore";

import { setDeviceId, removeAllStorage } from "../lib/storage";

// let instead of const because we want to be able to use redux devtools
let useAppStore = create(
  devtools(
    (set) => ({
      deviceId: null,
      mapsAPILoaded: false,
      hasErrors: false,
      equipmentId: null,
      loading: false,
      globalLoading: true,
      connected: 1,
      fieldEntryTrackingEnabled: false,

      // General info
      clientName: "",
      customerName: "",
      farms: [],
      farmName: "",
      fieldName: "",
      acreage: 0,
      /**
       * Routine responsible for loading all app data that would usually be called individually.
       * This will call 1 endpoint and set the store data the same way as all the individual
       * store files.
       */
      fetchInitialData: async () => {
        set(() => ({ loading: true, globalLoading: true }));
        try {
          const response = await client.get(initialDataUrl);
          // console.log(response);
          // Handle equipment ID return
          set((state) => ({ equipmentId: response.data.equipment.id }));

          // Handle equipment response
          useEquipmentStore.setState({
            equipment: response.data.equipment,
            originalCrewEquipmentIds: response.data.crew_equipment_ids,
            crewEquipment: response.data.crew_equipment,
            equipmentStatus: response.data.equipment.location_status,
            equipmentType: response.data.equipment.equipment_type,
            customerChoppers: response.data.customer_choppers,
          });

          // Handle crew response
          useCrewStore.setState({ crew: response.data.crew, crews: response.data.crews });

          // Handle dump locations response
          try {
            const processDumpLocationsResult = processFieldFeatures(response.data.dump_locations, true);
            useDumpLocationStore.setState({
              dumpLocations: response.data.dump_locations,
              crewDumpLocationsFeatures: processDumpLocationsResult.dumpLocationFeatures,
              crewDumpLocationsCoordinates: processDumpLocationsResult.dumpLocationsCoords,
            });
          } catch (e) {
            useDumpLocationStore.setState({
              dumpLocations: response.data.dump_locations,
              crewDumpLocationsFeatures: [],
              crewDumpLocationsCoordinates: [],
            });
          }

          // Field store response
          try {
            const processFieldsResult = processFieldFeatures(response.data.crew_fields);
            useFieldStore.setState({
              crewFields: response.data.crew_fields,
              crewFieldsIds: response.data.crew_fields_ids,
              crewFieldsFeatures: processFieldsResult.fieldFeatures,
              crewFieldsCoordinates: processFieldsResult.fieldsCoords,
            });
          } catch (e) {
            useFieldStore.setState({
              crewFields: response.data.crew_fields,
              crewFieldsIds: [],
              crewFieldsFeatures: [],
              crewFieldsCoordinates: [],
            });
          }

          // Crew trucks
          useEquipmentStore.setState({ crewTrucks: response.data.crew_trucks });

          // Map pins
          useCustomerMapPinStore.setState({ customerMapPins: response.data.map_pins });

          set(() => ({
            clientName: response.data.client_name,
            customerName: response.data.customer_name,
            farmName: response.data.farm_name,
            fieldName: response.data.field_name,
            acreage: response.data.acreage,
            loading: false,
            globalLoading: false,
            fieldEntryTrackingEnabled: response.data.field_entry_tracking_enabled,
            use_chopper_and_bagger_tickets: response.data.use_chopper_and_bagger_tickets,
            application_types: response.data.application_types,
            farms: response.data.farms,
          }));
        } catch (err) {
          set(() => ({ hasErrors: true, loading: false, globalLoading: false }));
        }
      },
      checkDeviceName: async (values) => {
        set(() => ({ loading: true, globalLoading: true }));
        try {
          const response = await client.post(checkDeviceMatch, values);
          if (response.data.success) {
            removeAllStorage();
            setDeviceId(values.device_name);
            set(() => ({ deviceId: values.device_name, loading: false, globalLoading: false }));
            useEquipmentStore.setState({ deviceName: values.device_name });
            useUIStore.setState({ showDeviceNameModal: false });
            window.location.reload(false);
            return true;
          } else {
            set(() => ({ loading: true, globalLoading: true }));
            return false;
          }
        } catch (err) {
          console.error(err);
        }
      },
      logErrorToServer: async (error, info) => {
        try {
          const response = await client.post(logErrorUrl, {
            error_log: JSON.stringify(error.stack),
            stack_info: info,
            device_id: useAppStore.getState().deviceId,
          });
          if (response.data.success) {
            return true;
          }
        } catch (err) {
          return false;
        }
      },
    }),
    { name: "AppStore" }
  )
);

export default useAppStore;
