import { useState } from "react";
import AWN from "awesome-notifications";
import { Formik, Form, Field, ErrorMessage } from "formik";
import client from "../../lib/client";
import { watchFocus } from "../../lib/form";
import useAppStore from "../../stores/appStore";
import { hoursTicketsUrl } from "../../lib/endpoints";
import useUIStore from "../../stores/uiStore";

export default function HoursTicketForm() {
  const [loading, setLoading] = useState(false);
  const farms = useAppStore((state) => state.farms);

  const handleSubmit = async (values, setSubmitting) => {
    setLoading(true);
    setSubmitting(false);

    const response = await client.post(hoursTicketsUrl, { hours_ticket: { ...values } });
    if (response.data.success) {
      new AWN().info("", {
        position: "top-right",
        durations: { info: 5000 },
        labels: { info: "Hours ticket created" },
        icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
      });
    } else {
      new AWN().info("There was an error when submitting this ticket. Please try again.", {
        position: "top-right",
        durations: { info: 5000 },
        labels: { info: "Error creating ticket" },
        icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
      });
    }

    setLoading(false);
    useUIStore.setState({ showHoursTicketModal: false });
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{ hours: "", farm_id: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.hours || values.hours === "0") {
            errors.hours = "Required";
          }
          if (!values.farm_id) {
            errors.farm_id = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}>
        {() => (
          <Form>
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="hours">
                    Hours
                  </label>
                  <Field name="hours" label="hours" type="number" innerRef={watchFocus} />
                  <ErrorMessage name="hours" component="div" className="formik-error" />
                </div>
                <div className="col">
                  <label className="formik-label" htmlFor="farm_id">
                    Farm
                  </label>
                  <Field name="farm_id" as="select">
                    <option value="">Select a farm</option>
                    {farms.map((farm) => (
                      <option key={farm.id} value={farm.id}>
                        {farm.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="farm_id" component="div" className="formik-error" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="formik-label" htmlFor="note">
                    Note
                  </label>
                  <Field
                    name="note"
                    label="note"
                    as="textarea"
                    maxLength="200"
                    style={{ width: "100%", height: "100px", resize: "vertical", fontSize: "30px" }}
                  />
                </div>
              </div>
            </div>
            <div className="action-stuck-bottom">
              <button type="submit" disabled={loading} className="btn btn-primary">
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
