export default null;

/**
 * Since a field can have many coordinate sets, we want to grab all of the location data
 * and store them in an array just to make it easier to process in function such as placeMe()
 */
export function processFieldFeatures(fieldsResponse, dumpLocationMode = false) {
  let fieldFeatures = [];
  let fieldFeatureCoords = [];

  for (const field of fieldsResponse) {
    for (const feature of field?.geojson?.features) {
      fieldFeatures.push({ ...feature, field_id: field.id });
      for (const coordCollection of feature.geometry?.coordinates) {
        for (const coord of coordCollection) {
          fieldFeatureCoords.push(coord);
        }
      }
    }
  }

  if (dumpLocationMode) {
    return { dumpLocationFeatures: fieldFeatures, dumpLocationsCoords: fieldFeatureCoords };
  } else {
    return { fieldFeatures: fieldFeatures, fieldsCoords: fieldFeatureCoords };
  }
}
