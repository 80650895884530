import create from "zustand";
import { devtools } from "zustand/middleware";

import client from "../lib/client";
import { dumpLocationsUrl } from "../lib/endpoints";

// let instead of const because we want to be able to use redux devtools
let useDumpLocationStore = (set) => ({
  dumpLocations: [],
  crewDumpLocationsFeatures: [],
  crewDumpLocationsCoordinates: [],
  lastDumpLocation: null,
  loading: false,
  hasErrors: false,
  fetchDumpLocations: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await client.get(dumpLocationsUrl);
      set((state) => ({ dumpLocations: response.data.dump_locations, loading: false }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
});

useDumpLocationStore = devtools(useDumpLocationStore, { name: "DumpLocationStore" });

export default useDumpLocationStore = create(useDumpLocationStore);
