import ReactModal from "react-modal";

import useUIStore from "../stores/uiStore";

import { getDeviceId, getIframeTicketUrl } from "../lib/storage";

export default function TicketModal() {
  const showTicketModal = useUIStore((state) => state.showTicketModal);

  const getTicketUrl = () => {
    if (!!getIframeTicketUrl()) {
      return getIframeTicketUrl();
    } else {
      return `${process.env.REACT_APP_TICKET_REPORTS_URL}?device=${getDeviceId()}`;
    }
  };

  return (
    <>
      <ReactModal
        // TODO: Set this
        ariaHideApp={false}
        isOpen={showTicketModal}
        // onAfterOpen={
        //   () => fetchCustomerFields()
        //   /* Function that will be run after the modal has opened. */
        // }
        contentLabel="Tickets"
        style={{
          overlay: {
            zIndex: 99999999,
          },
          content: {
            color: "lightsteelblue",
            backgroundColor: "#2d2e31",
          },
        }}>
        <div className="modal-body">
          <div className="modal-header">
            <h4 className="text-center">Tickets</h4>
            <button onClick={() => useUIStore.setState({ showTicketModal: false })}>
              Close <i className="fa-solid fa-times"></i>
            </button>
          </div>
          <div className="modal-content" style={{ paddingBottom: "0" }}>
            <iframe src={getTicketUrl()} title="tickets-frame" className="tickets-frame"></iframe>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
