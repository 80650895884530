const api = process.env.REACT_APP_API_URL;

export default api;

export const logErrorUrl = `${api}/log_error`;

// login
export const loginUrl = `${api}/auth/sign_in`;

// initial data bundle
export const initialDataUrl = `${api}/initial_data`;

// Crews
export const crewsUrl = `${api}/crews`;
export const crewUrl = (crewId) => `${crewsUrl}/${crewId}`;
// this is for fetching the crew for the current device
export const equipmentCrewUrl = `${api}/equipment_crew`;
// this is for fetching the equipment attached to a specific crew
// export const crewEquipmentUrl = `${crewUrl(crewId)}/equipment`
// this is for fetching the fields for a crew
export const specificCrewFieldsUrl = (crewId) => `${crewUrl(crewId)}/fields`;
export const updateCrewRoutesUrl = `${api}/update_crew_routes`;
export const deleteCrewRoutesUrl = `${api}/delete_crew_routes`;
export const coverageMapUrl = `${api}/coverage_map`;
export const crewUpdateEquipmentUrl = (crewId) => `${crewUrl(crewId)}/update_crew_equipment`;

// Crew chat
export const chatUrl = (crewId) => `${crewUrl(crewId)}/chat_messages`;

// Farms
export const farmsUrl = `${api}/farms`;

// Fields
export const customerFieldsUrl = `${api}/customer_fields`;
export const crewFieldsUrl = `${api}/crew_fields`;
export const farmFieldsUrl = `${api}/customer_farm_fields`;
export const updateAssignedFieldsUrl = `${api}/update_assigned_fields`;

// Dump Locations
export const dumpLocationsUrl = `${api}/dump_locations`;

// Customer Map Pins
export const customerMapPinsUrl = `${api}/customer_map_pins`;
export const customerMapPinUrl = (pinId) => `${customerMapPinsUrl}/${pinId}`;

// Equipment
export const equipmentUrl = `${api}/equipment`;
export const equipmentShowUrl = (equipmentId) => `${api}/equipment/${equipmentId}`;
export const equipmentStatusUrl = `${api}/equipment_status`;
export const customerEquipmentUrl = `${api}/customer_equipment`;
export const crewEquipmentUrl = `${api}/crew_equipment`;
export const crewTrucksUrl = `${api}/crew_trucks`;
export const equipmentLocationUrl = `${api}/equipment_location`;
export const equipmentHasCrewUrl = `${api}/equipment_has_crew`;
export const checkDeviceMatch = `${api}/equipment/check_device_match`;

// Gas Tickets
export const gasTicketsUrl = `${api}/gas_tickets`;

// Dump Tickets
export const dumpTicketsUrl = `${api}/dump_tickets`;

// Silage Tickets
export const silageTicketsUrl = `${api}/silage_tickets`;

// Hours Tickets
export const hoursTicketsUrl = `${api}/hours_tickets`;

// Scheduler
export const verifyPINUrl = `${api}/verify_schedule_pin`;
export const schedulesUrl = `${api}/schedules`;
export const scheduleUrl = (id) => `${api}/schedules/${id}`;
export const crewMembersUrl = `${api}/crew_members`;
export const crewMemberUrl = (id) => `${api}/crew_members/${id}`;
export const locationsUrl = `${api}/locations`;
export const locationUrl = (id) => `${api}/locations/${id}`;
export const equipmentAndCrewMembersUrl = (id) => `${scheduleUrl(id)}/equipment_crew_options`;
export const updateEquipmentCrewUrl = (id) => `${scheduleUrl(id)}/update_equipment_crew_members`;
export const confirmScheduleEquipmentUrl = `${api}/confirm_schedule_equipment`;

// Delete Coverage Map
export const deleteCoverageMapUrl = `${api}/delete_coverage_map_data`;

// Weather Statuses
export const weatherStatusesUrl = `${api}/weather_statuses`
