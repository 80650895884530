// import React from "react";
import { useEffect, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

import { getDeviceId, setReloadTimestamp, getReloadTimestamp, setIframeTicketUrl } from "./lib/storage";
import CrewCable from "./components/CrewCable";
import LoadingSpinner from "./components/LoadingSpinner";
import DeviceNameModal from "./components/DeviceNameModal";
import PlaceMe from "./components/PlaceMe";
import MapLayer from "./components/MapLayer";
import DumpLocationRenderer from "./components/DumpLocationRenderer";
import DrawRoute from "./components/DrawRoute";
import CoverageMap from "./components/CoverageMap";
import MapPins from "./components/MapPins";
import CrewModal from "./components/CrewModal";
import FieldReloadModal from "./components/FieldReloadModal";
import GasTicketModal from "./components/ticket_forms/GasTicketModal";
import DumpTicketModal from "./components/ticket_forms/DumpTicketModal";
import SilageBaggerModal from "./components/ticket_forms/SilageBaggerModal";
import SilageChopperModal from "./components/ticket_forms/SilageChopperModal";
import SilageTruckModal from "./components/ticket_forms/SilageTruckModal";
import HoursTicketModal from "./components/ticket_forms/HoursTicketModal";
import ProfileModal from "./components/ProfileModal";
import TicketModal from "./components/TicketModal";
import ScheduleModal from "./components/scheduler/ScheduleModal";
import PrintScheduleModal from "./components/scheduler/PrintScheduleModal";
import CreateCrewModal from "./components/CreateCrewModal";
import EquipmentMarkers from "./components/EquipmentMarkers";
import WeatherMarkers from "./components/WeatherMarkers";
import FieldEntryPointMarkers from "./components/FieldEntryPointMarkers";
import NearbyEquipment from "./components/NearbyEquipment";
import DeleteCoverageMapModal from "./components/DeleteCoverageMapModal";
import ShowHideWeatherPins from "./components/ShowHideWeatherPins";
import ShowHideFieldEntryPins from "./components/ShowHideFieldEntryPins";
import DisclaimerModal from "./components/DisclaimerModal";

import useAppStore from "./stores/appStore";
import useUIStore from "./stores/uiStore";
import useEquipmentStore from "./stores/equipmentStore";
import FieldRenderer from "./components/FieldRenderer";

import HeaderBar from "./components/HeaderBar";
import FooterBar from "./components/FooterBar";
import CrewDrawer from "./components/CrewDrawer";
import MapControls from "./components/MapControls";
import RightDrawer from "./components/RightDrawer";
import SecondaryRightDrawer from "./components/SecondaryRightDrawer";
import SelectTicketMenu from "./components/ticket_forms/SelectTicketMenu";
import TruckCards from "./components/TruckCards";
import CenterMap from "./components/CenterMap";

const render = (status) => {
  if (status === Status.SUCCESS) {
    // console.log("MAP LOADED");
    useAppStore.setState({
      mapsAPILoaded: true,
    });
  } else {
    // console.log(status);
  }
};

// const render = (status) => {
//   // if (status === Status.LOADING) return <h3>{status} ..</h3>;
//   // if (status === Status.FAILURE) return <h3>{status} ...</h3>;
//   // return null;
//   switch (status) {
//     case Status.LOADING:
//       console.log("loading");
//     case Status.FAILURE:
//       console.log("failed");
//     case Status.SUCCESS:
//       console.log("success");
//       useAppStore.setState({
//         mapsAPILoaded: true,
//       });
//     default:
//       console.log("default case");
//   }
// };

const App = () => {
  const [init, setInit] = useState(false);
  const deviceId = useAppStore((state) => state.deviceId);
  const textSize = useUIStore((state) => state.textSize);
  const equipment = useEquipmentStore((state) => state.equipment);
  const customerEquipment = useEquipmentStore((state) => state.customerEquipment);
  const crewEquipment = useEquipmentStore((state) => state.crewEquipment);
  const crewEquipmentIds = useEquipmentStore((state) => state.crewEquipmentIds);
  const originalCrewEquipmentIds = useEquipmentStore((state) => state.originalCrewEquipmentIds);
  const showDumpTicketModal = useUIStore((state) => state.showDumpTicketModal);
  const reloadRequired = useUIStore((state) => state.reloadRequired);
  const showModal = useUIStore((state) => state.showDisclaimerModal);

  const center = { lat: -34.397, lng: 150.644 };
  const zoom = 5;

  const fetchInitialData = useAppStore((state) => state.fetchInitialData);

  // Used to handle whether to show the disclaimer modal
  useEffect(() => {
    try {
      if (Math.abs(new Date() - new Date(getReloadTimestamp())) / 1000 > 10) {
        useUIStore.setState({ showDisclaimerModal: true });
      }
    } catch {}
  }, []);

  useEffect(() => {
    if (init && deviceId) {
      return;
    }

    // The device id is not set, we want to prompt the user to set it now
    if (getDeviceId() == null || getDeviceId() === "null" || getDeviceId() === "") {
      useUIStore.setState({ showDeviceNameModal: true });
    } else {
      if (deviceId == null) {
        useAppStore.setState({ deviceId: getDeviceId() });
      } else {
        // for whatever reason, this is re-rendering so fast that setInit doesn't have time to run
        // and update the component state before the next render happens, setting it directly
        // on the window seems to be faster and only runs the request once. we could also debounce it
        // with a very small timeout so it doesn't run twice.
        if (!window.initialFetch) {
          window.initialFetch = true;
          fetchInitialData();

          setInit(true);
        }
      }
    }
  }, [init, deviceId]);

  // Handling device refresh/reset on crew reassignment for your own device
  useEffect(() => {
    // This handles reloading if the current device reasigns itself to a different crew
    if (customerEquipment) {
      for (const e of customerEquipment) {
        if (e.id === equipment.id && e.crew_id !== equipment.crew_id) {
          setReloadTimestamp(new Date());
          window.location.reload(false);
        }
      }
    }

    // This handles reloading if the current device is removed from its crew by another device.
    // Two things must be true in order for this to work:
    //      1. This crew must have more than just this device in it
    //      2. The other devices in the crew need to be pinging
    // The data being passed from the crew cable updates the crewEquipment and this checks to make
    // sure that the current device still lives in that list. It will only know to update this when
    // another device makes a ping request to trigger the cable response
    let reload = true;
    for (const e of crewEquipment) {
      if (e.id === equipment.id) {
        reload = false;
      }
    }
    if (reload && equipment && equipment.crew_id) {
      setReloadTimestamp(new Date());
      window.location.reload(false);
    }
  }, [crewEquipment, customerEquipment]);

  // Handling device refresh/reset on crew reassignment for other crew devices.
  // We're comparing the newest list of crew equipment to the one we received on the initial data fetch.
  useEffect(() => {
    if (crewEquipmentIds.length > 0 && JSON.stringify(crewEquipmentIds) !== JSON.stringify(originalCrewEquipmentIds)) {
      if (showDumpTicketModal) {
        useUIStore.setState({ reloadRequired: true });
      } else {
        setReloadTimestamp(new Date());
        window.location.reload(false);
      }
    }
  }, [crewEquipmentIds]);

  useEffect(() => {
    if (!showDumpTicketModal && reloadRequired) {
      setReloadTimestamp(new Date());
      window.location.reload(false);
    }
  }, [showDumpTicketModal]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data?.type === "FROM_IFRAME") {
        // setIframeValue(event.data.myVariable);
        setIframeTicketUrl(event.data.ticketUrl);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <>
      <DeviceNameModal />
      <div className={textSize}>
        <LoadingSpinner />
        <Wrapper apiKey={"AIzaSyClTW9YIx-S0Ilx6ggcYOZREZcplwQlvlg"} render={render} version={"beta"} libraries={["drawing", "marker"]}>
          <MapLayer zoom={zoom} center={center}>
            <HeaderBar />
            <FooterBar />
            <TruckCards />
            <CrewDrawer />
            <MapControls />
            <RightDrawer />
            <SecondaryRightDrawer />
            <SelectTicketMenu />
            <DrawRoute />
            <CrewCable />
            <CoverageMap />
            <PlaceMe />
            <DumpLocationRenderer />
            <FieldRenderer />
            {/* <EquipmentList /> */}
            <MapPins />
            <EquipmentMarkers />
            <WeatherMarkers />
            <FieldEntryPointMarkers />
            <NearbyEquipment />
            <ProfileModal />
            <TicketModal />
            <CenterMap />
            <ShowHideWeatherPins />
            {/* Combined with the weather pins toggle */}
            {/* <ShowHideFieldEntryPins /> */}
          </MapLayer>
        </Wrapper>
        <CrewModal />
        <FieldReloadModal />
        <GasTicketModal />
        <DumpTicketModal />
        <SilageBaggerModal />
        <SilageChopperModal />
        <SilageTruckModal />
        <HoursTicketModal />
        <CreateCrewModal />
        <ScheduleModal />
        <PrintScheduleModal />
        <DeleteCoverageMapModal />
        <DisclaimerModal />
        {/* <br />
      <CrewFetch />
      <br />
      <FarmFetch />
      <br />
      <DumpLocationFetch />
      <br />
      <CustomerMapPinFetch />
      <br />
      <CrewImportForm />
      <br />
      <GasTicketForm />
      <br />
      <DumpTicketForm />
      <br />
      <SilageTicketForm />
      <br />
      <SilageTruckForm />
      <br />
      <SilageBaggerForm /> */}
      </div>
    </>
  );
};

export default App;
