import { useEffect, useState, useContext } from "react";
import moment from "moment";
import * as Sentry from "@sentry/react";

import { truckTypes, combineTypes, mowerMergerTypes } from "../lib/equipmentTypes";
import useCrewStore from "../stores/crewStore";
import useLocationStore from "../stores/locationStore";
import useEquipmentStore from "../stores/equipmentStore";
import useWeatherStore from "../stores/weatherStore";
import useDumpLocationStore from "../stores/dumpLocationStore";
import useFieldStore from "../stores/fieldStore";
import useUIStore from "../stores/uiStore";
import { MapContext } from "../components/MapLayer";
import {
  setMileage,
  incrementMileage,
  setLastField,
  getMileage,
  setLastDumpLocation,
  getTemperatureLogTime,
  getLocationStatus,
  setLocationStatus,
  getLocationTimestamp,
  setLocationTimestamp,
} from "../lib/storage";

Sentry.init({
  dsn: "https://9a3f97043ce881ca4d98038098dc26c0@o4504713329377280.ingest.us.sentry.io/4508994601025536",
});

export default function PlaceMe() {
  const mapContext = useContext(MapContext);

  const crew = useCrewStore((state) => state.crew);
  const myLocation = useLocationStore((state) => state.myLocation);
  const equipmentType = useEquipmentStore((state) => state.equipmentType);
  const equipment = useEquipmentStore((state) => state.equipment);
  const updateEquipment = useEquipmentStore((state) => state.updateEquipment);
  const updateEquipmentLocation = useEquipmentStore((state) => state.updateEquipmentLocation);
  const updateEquipmentStatus = useEquipmentStore((state) => state.updateEquipmentStatus);
  const logCurrentWeather = useWeatherStore((state) => state.logCurrentWeather);
  const crewFieldsFeatures = useFieldStore((state) => state.crewFieldsFeatures);
  const crewFieldsCoords = useFieldStore((state) => state.crewFieldsCoordinates);
  const dumpLocations = useDumpLocationStore((state) => state.dumpLocations);
  const dumpLocationsCoords = useDumpLocationStore((state) => state.crewDumpLocationsCoordinates);
  const lastDumpLocation = useDumpLocationStore((state) => state.lastDumpLocation);
  const percentFull = useEquipmentStore((state) => state.percentFull);

  const [lastLocation, setLastLocation] = useState(null);
  // const [odometer, setOdometer] = useState(null);
  const [status, setStatus] = useState("");
  const [fieldCenter, setFieldCenter] = useState(null);
  const [dumpCenter, setDumpCenter] = useState(null);
  const [lastUpdateAt, setLastUpdateAt] = useState(null);
  // const [distanceToDumpLocationCenter, setDistanceToDumpLocationCetner] = useState(0);

  const [fieldEntryPoint, setFieldEntryPoint] = useState(false);

  // Check the last location ping before restarting the app. If it's within one hour of now, use that as the
  // starting point
  useEffect(() => {
    if (!!getLocationStatus() && !!getLocationTimestamp() && Date.now() - getLocationTimestamp() <= 3600000) {
      setStatus(getLocationStatus());
    }
  }, []);

  useEffect(() => {
    if (crewFieldsCoords.length > 0) {
      try {
        // eslint-disable-next-line no-undef
        const features = turf.points(crewFieldsCoords);
        // eslint-disable-next-line no-undef
        setFieldCenter(turf.center(features));
      } catch (error) {
        try {
          // eslint-disable-next-line no-undef
          const features = turf.polygon(crewFieldsCoords);
          // eslint-disable-next-line no-undef
          setFieldCenter(turf.centerOfMass(features));
        } catch (error) {
          setFieldCenter(null);
        }
      }
    }
  }, [crewFieldsCoords]);

  useEffect(() => {
    if (dumpLocationsCoords.length > 0) {
      try {
        // eslint-disable-next-line no-undef
        const features = turf.points(dumpLocationsCoords);
        // eslint-disable-next-line no-undef
        setDumpCenter(turf.center(features));
      } catch (error) {
        try {
          // eslint-disable-next-line no-undef
          const features = turf.polygon(dumpLocationsCoords);
          // eslint-disable-next-line no-undef
          setDumpCenter(turf.centerOfMass(features));
        } catch (error) {
          setDumpCenter(null);
        }
      }
    }
  }, [dumpLocationsCoords]);

  useEffect(() => {
    // console.log("Status: ", status);
    // console.log("Mileage: ", getMileage());
    if (
      mapContext.map != null &&
      myLocation.lat != null &&
      myLocation.lng != null &&
      typeof myLocation.lat === "number" &&
      myLocation.lat >= -180 &&
      myLocation.lat <= 180 &&
      typeof myLocation.lng === "number" &&
      myLocation.lat >= -90 &&
      myLocation.lat <= 90
    ) {
      // centerOnLocation(myLocation);
      isDumpLocation(myLocation);
      isFieldLocation(myLocation);
    }

    try {
      if (status === "RF" && !crew?.manure_mode) {
        getETA(myLocation, status);
      } else if (status === "RD" && crew?.manure_mode) {
        getETA(myLocation, status);
      }
    } catch (error) {
      Sentry.captureException(error);
    }

    // updatePosition controls whether or not to actually post this location update
    // because it might be coming from the app
    if (myLocation.lat && myLocation.updatePosition) {
      updateEquipmentLocation({
        longitude: myLocation.lng,
        latitude: myLocation.lat,
        course: myLocation.course,
        location_status: status,
        speed: myLocation.speed,
        distance: myLocation.distance,
        field_entry_point: fieldEntryPoint,
        name: equipment?.name,
        date: new Date(),
      });

      // Log the current temperature if it has been more than 20 minutes since the last time temperature was logged.
      // The equipment must be a type of harvester and it must be in a field
      // 900000 ms = 15 minutes
      if (
        combineTypes.includes(equipmentType) &&
        isFieldLocation(myLocation) &&
        (!getTemperatureLogTime() || Date.now() - new Date(getTemperatureLogTime()) > 900000)
      ) {
        logCurrentWeather(myLocation);
      }
    }

    // If the status changes, need endpoint to update equipment status

    // This is happening from the native app
    // if (!lastUpdateAt || new Date() - lastUpdateAt > 2000) {
    //   updateEquipmentLocation({
    //     longitude: myLocation.lng,
    //     latitude: myLocation.lat,
    //     course: myLocation.course,
    //     location_status: status,
    //     date: new Date(),
    //   });
    //   setLastUpdateAt(new Date());
    // }
  }, [myLocation, status]);

  // useEffect(() => {
  //   console.log("Odo: ", odometer);
  // }, [odometer]);

  const centerOnLocation = (location) => {
    mapContext.map.setZoom(16);
    mapContext.map.setCenter(location);
  };

  const isDumpLocation = (location) => {
    let inDumpLocation = false;
    // eslint-disable-next-line no-undef
    const point = turf.point([location.lng, location.lat]);
    dumpLocations.every((dl) => {
      try {
        if (
          !!dl.geojson.features &&
          // eslint-disable-next-line no-undef
          dl.geojson.features.some((feature) => turf.booleanPointInPolygon(point, turf.polygon(feature.geometry.coordinates)))
        ) {
          useDumpLocationStore.setState({ lastDumpLocation: dl });
          setLastDumpLocation(dl.id);

          // If the crew is in manure mode, we want to start tracking the mileage from the time they
          // leave the dump to the time they enter the field. For non-manure-mode crews, it's the opposite.
          // Non-manure-mode crews start tracking mileage when leaving the field and arrive at the dump location.
          // When not in manure mode and entering a dump location, show the ticket modal.
          if (crew?.manure_mode) {
            setMileage(null);
          }

          // If we're not in manure mode and we're in a dump, find the distance from where we are to the dump center
          if (!crew?.manure_mode) {
            try {
              // eslint-disable-next-line no-undef
              const dumpLocationCenter = turf.centroid(dl.geojson.features[0]);
              // eslint-disable-next-line no-undef
              const distanceToCenter = turf.distance(dumpLocationCenter, point, { units: "miles" });
              incrementMileage(distanceToCenter.toFixed(2));
            } catch (e) {
              console.log(e);
            }
          }

          inDumpLocation = true;
          updateLocationStatus("D", dl.notify);
          // Falsey values terminate loop
          return false;
        } else {
          return true;
        }
      } catch (error) {
        return true;
      }
    });

    if (!inDumpLocation && lastLocation && crew?.manure_mode) {
      calculateOdometer(point);
    }

    if (!inDumpLocation && status === "D") {
      updateLocationStatus("RF");
      // Get the distance from this point to the center of the dump location when leaving the dump.
      // This is for manure mode crews to account for the distance from entering the dump to wherever
      // they're actually going to.
      if (crew?.manure_mode) {
        try {
          // eslint-disable-next-line no-undef
          const dumpLocationCenter = turf.centroid(
            dumpLocations.find((location) => location.id === lastDumpLocation.id).geojson.features[0]
          );
          // eslint-disable-next-line no-undef
          const distanceToCenter = turf.distance(dumpLocationCenter, point, { units: "miles" });
          incrementMileage(distanceToCenter.toFixed(2));
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const isFieldLocation = (location) => {
    // eslint-disable-next-line no-undef
    const point = turf.point([location.lng, location.lat]);
    let inField = false;

    crewFieldsFeatures.every((fieldFeature) => {
      // eslint-disable-next-line no-undef
      const fieldPoly = turf.helpers.polygon(fieldFeature.geometry.coordinates);
      // eslint-disable-next-line no-undef
      if (turf.booleanPointInPolygon(point, fieldPoly)) {
        // window.localStorage.setItem("pipe-last-field", field.id);
        // useLocationStore.setState({
        //   miles: odometer,
        // });

        // If the crew is in manure mode, we want to show the dump ticket form since we are tracking
        // mileage from the dump to the field. If the crew is not in manure mode, we want to reset the mileage.
        if (!crew?.manure_mode) {
          setMileage(null);
        }

        setLastField(fieldFeature.field_id);

        inField = true;
        updateLocationStatus("F", true);
        // console.log("WE ARE IN FIELD: ", fieldFeature);
        // console.log("Resetting odometer to 0.");
        return false;
      } else {
        return true;
      }
    });

    // This is what calls mileage tracking. We want it to start now now when they leave
    // a field, but when they start loading from a chopper. I'm just using the percentFull
    // attribute to say, "if we are in a loading/loading state, start tracking mileage now"
    if (!!percentFull && percentFull > 0 && lastLocation && !crew?.manure_mode) {
      calculateOdometer(point);
    }

    if (!inField) {
      if (status === "F") {
        updateLocationStatus("RD");
      } else if (status === "") {
        updateLocationStatus("Driving");
      }
    }

    if (!inField) {
      setFieldEntryPoint(false);
    }

    setLastLocation(point);

    return inField;
  };

  const calculateOdometer = (point) => {
    if (getMileage() == null || getMileage() === "null") {
      setMileage(0);
      // console.log("Starting odo tracking");
    } else {
      // eslint-disable-next-line no-undef
      const traveled = turf.distance(lastLocation, point, { units: "miles" });
      // traveling more than one mile is the result of a faulty geo ping
      if (traveled <= 1) {
        // setOdometer(odometer + traveled);
        incrementMileage(traveled);
      }
    }
  };

  const updateLocationStatus = (newStatus, openDumpTicketModal = false) => {
    setLocationStatus(newStatus);
    setLocationTimestamp(Date.now());

    if (status !== newStatus) {
      setStatus(newStatus);
      updateEquipmentStatus(newStatus);

      // If the MOWER OR MERGER is entering the field from outside the field, we want to mark this
      // so we know to set the fieldEntryPoint value on the EquipmentLocation record we create
      // on the backend.
      if (mowerMergerTypes.includes(equipmentType) && status !== "" && newStatus === "F") {
        setFieldEntryPoint(true);
      }

      // If the TRUCK is (entering a field and is NOT in manure mode) OR (entering a dump and in manure mode),
      // check if this crew has silage enabled. If it does, show the
      // silage truck form instead of the dump ticket form.
      if (
        truckTypes.includes(equipmentType) &&
        openDumpTicketModal &&
        ((newStatus === "D" && !crew?.manure_mode) || (newStatus === "F" && crew?.manure_mode))
      ) {
        // We just use silage mode now
        useUIStore.setState({ showSilageTruckModal: true });

        // if (crew.silage_enabled) {
        //   useUIStore.setState({ showSilageTruckModal: true });
        // } else {
        //   useUIStore.setState({ showDumpTicketModal: true });
        // }
      }
    } else if (mowerMergerTypes.includes(equipmentType)) {
      // if status has not changed (we're in the same type of location as the last ping) we can set the
      // field entry to false so we stop marking any new update as a field entry point until they actually
      // enter the field again.
      setFieldEntryPoint(false);
    }
  };

  const getETA = (currentLocation, status) => {
    // console.log("STATUS FROM INSIDE ETA: ", status);
    // Don't call ETA more than once every two minutes
    if (
      ((status === "RF" && fieldCenter != null) || (status === "RD" && dumpCenter != null)) &&
      (!lastUpdateAt || new Date() - lastUpdateAt > 180000)
    ) {
      const service = new window.google.maps.DistanceMatrixService();

      const origin = { lat: currentLocation.lat, lng: currentLocation.lng };
      const destination = crew?.manure_mode
        ? { lat: dumpCenter.geometry.coordinates[1], lng: dumpCenter.geometry.coordinates[0] }
        : { lat: fieldCenter.geometry.coordinates[1], lng: fieldCenter.geometry.coordinates[0] };

      const request = {
        origins: [origin],
        destinations: [destination],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
        avoidHighways: false,
        avoidTolls: false,
      };

      // TODO: Might need to make this divided by 60

      service
        .getDistanceMatrix(request)
        .then((response) => {
          if (response && response.rows) {
            // console.log("made network request");
            const eta_seconds = response.rows[0].elements[0].duration.value;
            const etaTimestamp = moment().add(eta_seconds, "seconds");
            updateEquipment({ last_value: eta_seconds, eta_timestamp: etaTimestamp });
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
        });

      setLastUpdateAt(new Date());
    }
  };
}
