import useUIStore from "../stores/uiStore";
import useCrewStore from "../stores/crewStore";

export default function EditCrew() {
  const crews = useCrewStore((state) => state.crews);
  const showEditCrewModal = useUIStore((state) => state.showEditCrewModal);

  return (
    <>
      {crews.length > 0 && (
        <div onClick={() => useUIStore.setState({ showEditCrewModal: !showEditCrewModal })}>
          <i className="fa-solid fa-pencil"></i> EDIT CREW
        </div>
      )}
    </>
  );
}
