import create from "zustand";
import { devtools } from "zustand/middleware";

import useChatStore from "./chatStore";
import { getShowWeatherPins, setShowWeatherPins, getTextSize, setTextSize } from "../lib/storage";

let useUIStore = (set) => ({
  reloadRequired: false,
  textSize: getTextSize(),
  hideCrewDrawer: false,
  showRightMenu: true,
  showSecondaryRightMenu: false,
  showRoutesMenu: false,
  showCoverageMap: true,
  showPinModal: false,
  showFieldModal: false,
  showFieldReloadModal: false,
  showEditCrewModal: false,
  showCreateCrewModal: false,
  showScheduleModal: false,
  showMapControls: false,
  // make this a const that whole app uses as default
  mapZoomLevel: 15,
  // az, za
  crewEquipmentSortAlpha: null,
  //nearest, farthest
  crewEquipmentSortDistance: null,
  showProfileModal: false,
  // Device Name modal
  showDeviceNameModal: false,
  requireDeviceNamePasscode: false,
  // Ticket modals
  showTicketModal: false,
  showTicketMenu: false,
  showGasTicketModal: false,
  showDumpTicketModal: false,
  showSilageBaggerModal: false,
  showSilageTruckModal: false,
  showHoursTicketModal: false,
  showSilageChopperModal: false,
  // Delete coverage map modal
  showDeleteCoverageMapModal: false,
  // Weather pins
  showWeatherPins: getShowWeatherPins(),
  // Field entry pins
  showFieldEntryPins: true,
  // disclaimer/terms modal
  showDisclaimerModal: false,

  setTextSize: async (size) => {
    const currentTextSize = useUIStore.getState().textSize;

    if (currentTextSize === "") {
      setTextSize("bigger");
      set((state) => ({ textSize: "bigger" }));
    } else if (currentTextSize === "bigger") {
      setTextSize("biggest");
      set((state) => ({ textSize: "biggest" }));
    } else {
      setTextSize("");
      set((state) => ({ textSize: "" }));
    }
  },
  openMenuItem: async (menuItem) => {
    // set((state) => ({ newMessage: message }));
    switch (menuItem) {
      case "tickets":
        set((state) => ({ showTicketMenu: !state.showTicketMenu, showRoutesMenu: false, showRightMenu: false }));
        useChatStore.setState({ open: false });
        break;
      case "routes":
        set((state) => ({ showTicketMenu: false, showRoutesMenu: !state.showRoutesMenu, showRightMenu: false }));
        useChatStore.setState({ open: false });
        break;
      case "chat":
        set((state) => ({ showTicketMenu: false, showRoutesMenu: false, showRightMenu: false }));
        useChatStore.setState({ open: !useChatStore.getState().open, unread: false });
        break;
      case "sidebar":
        set((state) => ({ showTicketMenu: false, showRoutesMenu: false, showRightMenu: !state.showRightMenu }));
        useChatStore.setState({ open: false });
        break;
      default:
        set((state) => ({ showTicketMenu: !state.showTicketMenu, showRoutesMenu: false, showRightMenu: false }));
        useChatStore.setState({ open: false });
        break;
    }
  },
  toggleShowWeatherPins: async () => {
    const showing = useUIStore.getState().showWeatherPins;
    setShowWeatherPins(!showing);
    set((state) => ({ showWeatherPins: !showing }));
  },
});

useUIStore = devtools(useUIStore, { name: "UIStore" });

export default useUIStore = create(useUIStore);

// export default useUIStore;
