import ReactModal from "react-modal";
import useUIStore from "../../stores/uiStore";
import HoursTicketForm from "./HoursTicketForm";

export default function HoursTicketModal() {
  const showHoursTicketModal = useUIStore((state) => state.showHoursTicketModal);

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={showHoursTicketModal}
      contentLabel="Hours Ticket"
      style={{
        overlay: {
          zIndex: 99999999,
        },
        content: {
          color: "lightsteelblue",
          backgroundColor: "#2d2e31",
        },
      }}>
      <div className="modal-body">
        <div className="modal-header">
          <h4 className="text-center">Hours Ticket</h4>
          <button onClick={() => useUIStore.setState({ showHoursTicketModal: false })}>
            Close <i className="fa-solid fa-times"></i>
          </button>
        </div>
        <div className="modal-content">
          <HoursTicketForm />
        </div>
      </div>
    </ReactModal>
  );
}
